import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Munkáinkból',
    paragraph: 'Nézzen meg néhányat legjobb munkáinkból. Nézzen párat azok közül az alkalmazások közül, amelyeket saját eszközeinkkel és szakértelmünkkel sikeresen fejlesztettünk és értékesítettünk.'
  };

  return (
    <section
      id="section-jobs"
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {/*<Carousel>*/}
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    Számlázó és beléptető rendszer felhőbe integrálása.
                  </p>
                  <p className="text-sm mb-0">
                    Jegyértékesítő és ellenőrző alkalmazások
                  </p>
                  <p className="text-sm mb-0">
                    Valós idejú adatok a parkolásokról
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {/*
                  <span className="testimonial-item-name text-color-high">Park and Go</span>
                  <span className="text-color-low"> / </span>
                  */}
                  <span className="testimonial-item-link">
                    <a href="https://parkandgo.hu">Park and Go</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    EEG Neurofeedback alkalmazás fejlesztés relaxáció és koncentráció mérésére és vizuális megjelenítésére
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {/*
                  <span className="testimonial-item-name text-color-high">Park and Go</span>
                  <span className="text-color-low"> / </span>
                  */}
                  <span className="testimonial-item-link">
                    <a href="https://gotrabigo.com">Go Trabi Go</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    Strand és vízi élmény park applikáció
                  </p>
                  <p className="text-sm mb-0">
                    Jegyértékesitő és ellenőrző alkalmazás
                  </p>
                  <p className="text-sm mb-0">
                    Információs kioszkok telepítése, üzemeltetése a strand területén
                  </p>

                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {/*<span className="testimonial-item-name text-color-high">Rukkel Tó Water park</span>
                  <span className="text-color-low"> / </span>*/}
                  <span className="testimonial-item-link">
                    <a href="https://rukkel.hu">Rukkel Tó Water park</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    Elektromos autót használók részére fejlesztett road assistance alkalmazás
                  </p>
                  <p className="text-sm mb-0">
                    Alkalmazás a felhasználóknak és a szolgáltatónak.
                  </p>
                  <p className="text-sm mb-0">
                    Töltő autók realtime követése
                  </p>
                  <p className="text-sm mb-0">
                    Alkamazás összekötése a töltőberendezéssel
                  </p>

                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {/* 
                  <span className="testimonial-item-name text-color-high">Green Assistance</span>
                  <span className="text-color-low"> / </span>
                  */}
                  <span className="testimonial-item-link">
                    <a href="https://greenassistance.hu">Green Assistance</a>
                  </span>
                </div>
              </div>
            </div>





            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    iOS alkalmazás melyben segítségével ARKit segítségével van lehetőség a falakat és autót átfesteni, a szükséges mennyiséget kiszámolni.
                  </p>

                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  {/*
                  <span className="testimonial-item-name text-color-high">Park and Go</span>
                  <span className="text-color-low"> / </span>
                  */}
                  <span className="testimonial-item-link">
                    <a href="https://apps.apple.com/app/ipaint-ar-room-car-designer/id1573875240">Kövesdi Kft</a>
                  </span>
                </div>
              </div>
            </div>
            {/*</Carousel>*/}
          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;