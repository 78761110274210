import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: '',
    paragraph: 'A Pixelkaktusz innovatív mobilalkalmazásokat készít és forgalmaz. Módszeres és világos fejlesztési folyamatot alkalmazunk a minőség biztosítása érdekében, miközben alacsonyan tartjuk a költségeket, és átfogó, adatvezérelt marketingfolyamatot alkalmazunk, amely lehetővé teszi számunkra, hogy egy erős felhasználói bázist építsünk ki a termékek és szolgáltatások köré.'
  };

  return (
    <section
      id="section-intro"
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                </div>
                <h3 className="mt-0 mb-12">

                </h3>
                <p className="m-0">
                  Mobil alkalmazás készítés folyamatával ötletes, innovatív elképzelések valósíthatók meg, felhasználva a mobiltelefonok és tabletek (Android, iOS, iPhone, tablet, iPad) adta lehetőségeket úgy, mint például a lokalizáció (GPS), gesztúrák (kézmozdulatok), push üzenetek (push notification) és további hardware szenzorok használata.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                </div>
                <h3 className="mt-0 mb-12">

                </h3>
                <p className="m-0">
                  A mobil applikáció fejlesztés épülhet önmagában egy jó elképzelésre, de kapcsolódhat, kiegészíthet már kialakult online marketing felületeket, úgy mint céges weboldal, honlap, hírportál, turisztikai infóoldal, stb.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                </div>
                <h3 className="mt-0 mb-12">

                </h3>
                <p className="m-0">

                  Amikor új ügyfél érkezik hozzánk, a folyamat az alkalmazásfejlesztéssel kezdődik. Biztosítjuk megrendelőinket, hogy alkalmazásaink a legjobb UX/UI-t szem előtt tartva készüljenek és mivel a marketinggel is mi foglalkozunk, értjük, hogy a felhasználók mit szeretnének látni, és hogyan fogják használni az alkalmazást.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;